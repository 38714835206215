import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { EventService } from '@shared/event.service';
import { SidebarCloseEvent, SidebarToggleEvent } from '@shared/types/events';

/**
 * Application bootstrap component which provides outer layout with header, footer and navigation sidebar.
 * Furthermore it provides a router-outlet where the pages will be injected.
 *
 * This component observes the events {@link SidebarCloseEvent} and {@link SidebarToggleEvent} to close and toggle the navigation sidebar.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

  /**
   * Component attribute of the navigation sidebar
   */
  @ViewChild(MatSidenav) matSidenav!: MatSidenav;

  /**
   * @constructor
   * Constructor to create a instance of this angular component.
   *
   * @param {EventService} eventService angular service of class {@link EventService}
   */
  constructor(private eventService: EventService) { }

  /**
   * This method subscribes to the events {@link SidebarCloseEvent} and {@link SidebarToggleEvent}.
   */
  ngAfterViewInit(): void {
    this.eventService.get(SidebarCloseEvent).subscribe(() => this.matSidenav.close());
    this.eventService.get(SidebarToggleEvent).subscribe(() => this.matSidenav.toggle());
  }
}
