
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '@root/material.module';
import { RoutingModule } from '@root/routing.module';
import { SharedModule } from '@shared/shared.module';
import { FooterComponent } from './footer.component';
import { HeaderComponent } from './header.component';
import { SidebarHideDirective } from './sidebar/directives/sidebar.hide.directive';
import { SidebarSwitchDirective } from './sidebar/directives/sidebar.switch.directive';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarItemComponent } from './sidebar/sidebar.item.component';

/**
 * Angular module to encapsulate the declarable objects of the outer layout.
 * This module contains all declarable objects for the header, footer and sidebar.
 */
@NgModule({
  declarations: [
    SidebarSwitchDirective,
    SidebarHideDirective,
    SidebarComponent,
    SidebarItemComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RoutingModule,
    SharedModule,
    MaterialModule,
    FontAwesomeModule
  ],
  exports: [
    SidebarSwitchDirective,
    SidebarHideDirective,
    SidebarComponent,
    SidebarItemComponent,
    HeaderComponent,
    FooterComponent
  ]
})
export class LayoutModule { }
