
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@root/material.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AnchorComponent } from './components/anchor.component';
import { SortableTableComponent } from './components/sortable-table.component';
import { TestBarChartComponent } from './components/test-bar-chart.component';
import { TestTreeComponent } from './components/test-tree.component';
import { VulnerabilityOverviewComponent } from './components/vulnerability-overview.component';
import { AnchorDirective } from './directives/anchor.directive';
import { ConfidenceFilterDirective } from './directives/confidence.filter.directive';
import { TableDetailsOpenDirective } from './directives/table-details.open.directive';

/**
 * Angular module to provide shared directives or components.
 */
@NgModule({
  declarations: [
    AnchorDirective,
    TableDetailsOpenDirective,
    ConfidenceFilterDirective,
    SortableTableComponent,
    VulnerabilityOverviewComponent,
    TestBarChartComponent,
    TestTreeComponent,
    AnchorComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NgApexchartsModule
  ],
  exports: [
    AnchorDirective,
    TableDetailsOpenDirective,
    SortableTableComponent,
    VulnerabilityOverviewComponent,
    TestBarChartComponent,
    TestTreeComponent,
    AnchorComponent
  ]
})
export class SharedModule { }
