import { ChartData } from './chart-data.model';
import { Confidence } from './confidence';
import { Status } from './status';
import { TableData } from './table-data.model';

/**
 * Abstract class of application based events
 */
export abstract class AppEvent {

  /**
   * @constructor
   * Default constructor to initialize the {@link event} attriute.
   * @param {Event} event the value of the event
   */
  constructor(readonly event: Event) { }
}

/**
 * Event to trigger when the sidebar should be toggled
 */
export class SidebarToggleEvent extends AppEvent { }

/**
 * Event to trigger when the sidebar closes
 */
export class SidebarCloseEvent extends AppEvent { }

/**
 * Event to trigger on page {@link SastReportComponent} when the details closes
 */
export class SastDetailCloseEvent extends AppEvent { }

/**
 * Event to trigger on table click to open the details view
 */
export class TableDetailOpenEvent extends AppEvent {
  /**
   * @constructor
   * Default constructor of this {@link AppEvent}.
   * This constructor will call the inherited constructor of {@link AppEvent}.
   *
   * @param {Event} event event of the trigger to pass to constructor of {@link AppEvent}
   * @param {TableData} data data to pass to the subscriber from the element how triggered this event
   */
  constructor(override event: Event, readonly data?: TableData) {
    super(event);
  }
}

/**
 * Event to trigger on page {@link DependenciesComponent} when the details closes
 */
export class DependenciesDetailCloseEvent extends AppEvent { }

/**
 * Event to trigger on page {@link PentestComponent} when the details closes
 */
export class PentestDetailCloseEvent extends AppEvent { }

/**
 * Event to trigger when a {@link Confidence} filter is selected in any part of this application.
 */
export class FilterByConfindenceEvent extends AppEvent {

  /**
   * @constructor
   * Default constructor of this {@link AppEvent}.
   * This constructor will call the inherited constructor of {@link AppEvent}.
   *
   * @param {Event} event event of the trigger to pass to constructor of {@link AppEvent}
   * @param {Confidence} confidence the confidence to use as filter for the data.
   */
  constructor(override event: Event, readonly confidence: Confidence) {
    super(event);
  }
}

/**
 * Event to trigger when a {@link Status} filter is selected in any part of this application.
 */
export class FilterByStatusEvent extends AppEvent {

  /**
   * @constructor
   * Default constructor of this {@link AppEvent}.
   * This constructor will call the inherited constructor of {@link AppEvent}.
   *
   * @param {Event} event event of the trigger to pass to constructor of {@link AppEvent}
   * @param {Status[]} status the status array to use as filter for the data.
   */
  constructor(override event: Event, readonly status: Status[]) {
    super(event);
  }
}

/**
 * Event to trigger when a {@link Status} the chart data should be updated with the given array of {@link ChartData}
 */
export class UpdateChartDataEvent extends AppEvent {

  /**
   * @constructor
   * Default constructor of this {@link AppEvent}.
   * This constructor will call the inherited constructor of {@link AppEvent}.
   *
   * @param {Event} event event of the trigger to pass to constructor of {@link AppEvent}
   * @param {ChartData[]} data the updated data array which should be set as data to a chart component
   */
  constructor(override event: Event, readonly data: ChartData[]) {
    super(event);
  }
}
