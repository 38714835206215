import { Component, OnInit } from '@angular/core';
import { ActivationEnd, NavigationStart, Router } from '@angular/router';
import { RoutingData } from '@shared/types/routing-data.model';
import { filter, map, Observable } from 'rxjs';

/**
 * Angular component to render and interact with the header.
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  /**
   * Attribute of the text to render as current page name.
   */
  pagename?: string;

  /**
   * @constructor
   * Constructor to inject router service
   *
   * @param {Router} router router service to subscribe to routing events
   */
  constructor(private readonly router: Router) { }

  /**
   * @inheritDoc {@link OnInit.ngOnInit}
   *
   * Method will subscribe to routing events to reset page name on {@link NavigationStart}
   * and sets the new page name on {@link ActivationEnd}.
   */
  ngOnInit() {
    // clear pagename on navigation start
    this.observeNavigationStart().subscribe(() => this.pagename = '');
    // set pagename by activated route
    this.observeActivationEnd().subscribe(data => this.pagename = data.title);
  }

  /**
   * Filters routing events for all instances of NavigationStart
   * @returns {Observable}
   */
  private observeNavigationStart(): Observable<NavigationStart> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      map(event => event as NavigationStart)
    );
  }

  /**
   * Method produces a Observable for routing events for events of type {@link ActivationEnd}
   * and maps it to {@link RoutingData} by accessing data of {@link ActivatedRouteSnapshot}
   * @returns {Observable}
   */
  private observeActivationEnd(): Observable<RoutingData> {
    return this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map(event => event as ActivationEnd),
      map(event => event.snapshot),
      map(snapshot => snapshot.data as RoutingData),
      filter(data => data.title !== undefined)
    );
  }
}
