<mat-nav-list>
    <app-sidebar-item home [link]="'/home'" [label]="'home'" [icon]="'home'"></app-sidebar-item>
    <mat-expansion-panel [class.mat-elevation-z0]="true" dense source-menu>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>code</mat-icon><span class="nav-caption">source</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list dense>
            <app-sidebar-item readme [link]="'/readme'" [label]="'README'" [icon]="'article'"></app-sidebar-item>
            <app-sidebar-item docs [link]="docsUrl" [label]="'docs'" [icon]="'history_edu'" [external]="true"></app-sidebar-item>
            <app-sidebar-item gitlab [link]="gitlabUrl" [label]="'gitlab'" [icon]="'merge'" [external]="true"></app-sidebar-item>
            <app-sidebar-item codiga [link]="codigaUrl" [label]="'codiga'" [icon]="'donut_small'" [external]="true"></app-sidebar-item>
            <app-sidebar-item semgrep [link]="semgrepUrl" [label]="'semgrep'" [icon]="'security'" [external]="true"></app-sidebar-item>
            <app-sidebar-item zap [link]="'/pentest'" [label]="'penetration tests'" [icon]="'policy'"></app-sidebar-item>
            <app-sidebar-item tests [link]="'/unit-tests'" [label]="'unit tests'" [icon]="'checklist'"></app-sidebar-item>
            <app-sidebar-item e2e [link]="'/e2e-tests'" [label]="'end-to-end tests'" [icon]="'groups'"></app-sidebar-item>
            <app-sidebar-item dependencies [link]="'/dependencies'" [label]="'dependency check'" [icon]="'safety_check'"></app-sidebar-item>
            <app-sidebar-item sast-report [link]="'/sast-report'" [label]="'sast report'" [icon]="'security'"></app-sidebar-item>
            <app-sidebar-item license-compliance [link]="'/license-compliance'" [label]="'license compliance'" [icon]="'camera_outdoor'"></app-sidebar-item>
        </mat-nav-list>
    </mat-expansion-panel>
    <app-sidebar-item imprint [link]="'assets/legals.html'" [label]="'imprint & legals'" [icon]="'gavel'" [external]="true"></app-sidebar-item>
</mat-nav-list>
<div class="spacer"></div>
<mat-nav-list class="profile-container">
    <app-anchor [link]="'https://www.buymeacoffee.com/hazelcomb'" [external]="true">
        <mat-icon>local_cafe</mat-icon>
    </app-anchor>
    <app-anchor [link]="'https://www.linkedin.com/in/fabianweu'" [external]="true">
        <fa-icon [icon]="linkedin"></fa-icon>
    </app-anchor>
    <app-anchor [link]="mailto" [external]="true">
        <mat-icon>bug_report</mat-icon>
    </app-anchor>
</mat-nav-list>