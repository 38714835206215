import { Component } from '@angular/core';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

/**
 * Angular component to render the navigation sidebar.
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {

  /**
   * Url of the documentation
   */
  readonly docsUrl: string = 'documentation/index.html';

  /**
   * Url of the gitlab group from the company
   */
  readonly gitlabUrl: string = 'https://gitlab.com/hazelcomb';

  /**
   * Url of the code quality tool 'codiga'
   */
  readonly codigaUrl: string = 'https://app.codiga.io/home';

  /**
   * Url of the security complicance tool 'semgrep
   */
  readonly semgrepUrl: string = 'https://semgrep.dev/orgs/hazelcomb/findings';

  /**
   * Url of the end-to-end test report
   */
  readonly e2eUrl: string = 'reports/playwright/index.html';

  /**
   * Url of the penetration testing report
   */
  readonly zapUrl: string = 'reports/zap-report.html';

  /**
   * Icon defintion of the profile anchor
   */
  readonly linkedin = faLinkedin;

  /**
   * mailto link of the gitlab issue reporting.
   */
  get mailto(): string {
    const emailAlias = 'incoming+hazelcomb-angular-core-34272423-mnn9rcuvefnpttjg3u5bhe5b-issue';
    return `mailto:${emailAlias}@incoming.gitlab.com?subject=Enter%20the%20issue%20title&body=Enter%20the%20issue%20description`;
  }
}
