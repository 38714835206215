import { Directive, HostListener } from '@angular/core';
import { SidebarToggleEvent } from '@shared/types/events';
import { EventService } from '@shared/event.service';

/**
 * Angular directive for the selector '[sidebar-switch]'.
 * This directive adds a click event listener to the element to emit a custom application event by using {@link EventService}.
 * The Event {@link SidebarToggleEvent} will be emitted when the host element is clicked.
 */
@Directive({ selector: '[sidebar-switch]' })
export class SidebarSwitchDirective {

  /**
   * @constructor
   * Constructor to inject application services
   *
   * @param {EventService} eventService service to emit and subscribe to events
   */
  constructor(private eventService: EventService) { }

  /**
   * Method to execute when the host element is clicked.
   *
   * @param $event client event of type {@link Event}
   */
  @HostListener('click', ['$event'])
  click($event: Event): void {
    this.eventService.emit(new SidebarToggleEvent($event));
  }
}
